const StoreNumber = (store) => {

    switch(store) {
        case "Aklavik":
            return "100";
        case "Akulivik":
            return "103";
        case "Albany":
            return "102";
        case "Arctic Bay":
            return "104";
        case "Arviat":
            return "224";
        case "Attawapiskat":
            return "106";
        case "Baker Lake":
            return "120";
        case "Bearskin Lake":
            return "124";
        case "Beauval":
            return "126";
        case "Behchoko":
            return "259";
        case "Berens River":
            return "128";
        case "Black Lake":
            return "132";
        case "Brochet":
            return "138";
        case "Buffalo Narrows":
            return "142";
        case "Cambridge Bay":
            return "150";
        case "Cape Dorset":
            return "152";
        case "Cartwright":
            return "154";
        case "Cat Lake":
            return "156";
        case "Chesterfield Inlet":
            return "158";
        case "Chisasibi":
            return "164";
        case "Churchill":
            return "166";
        case "Clyde River":
            return "168";
        case "Coral Harbour":
            return "172";
        case "Cross Lake":
            return "176";
        case "Cumberland House":
            return "178";
        case "Deer Lake":
            return "185";
        case "Deline":
            return "246";
        case "Eastmain":
            return "212";
        case "Fond du Lac":
            return "242";
        case "Fort Chipewyan":
            return "244";
        case "Fort Good Hope":
            return "248";
        case "Fort Liard":
            return "250";
        case "Fort McPherson":
            return "252";
        case "Fort Providence":
            return "258";
        case "Fort Resolution":
            return "260";
        case "Fort Simpson":
            return "268";
        case "Fort Smith":
            return "272";
        case "Fox Lake":
            return "278";
        case "Gjoa Haven":
            return "294";
        case "Gods Narrows":
            return "296";
        case "Gods River":
            return "300";
        case "Hall Beach":
            return "310";
        case "Happy Valley":
            return "312";
        case "Hay River":
            return "317";
        case "Igloolik":
            return "340";
        case "Ile a la Crosse":
            return "344";
        case "Inukjuak":
            return "346";
        case "Inuvik":
            return "348";
        case "Iqaluit":
            return "350";
        case "Island Lake":
            return "354";
        case "Kangiqsualujjuaq":
            return "371";
        case "Kangiqsujuaq":
            return "370";
        case "Kangirsuk":
            return "372";
        case "Kasabonika":
            return "373";
        case "Kashechewan":
            return "376";
        case "Keewaywin":
            return "377";
        case "Kimmirut":
            return "406";
        case "Kugluktuk":
            return "170";
        case "Kuujjuaq":
            return "378";
        case "Kuujjuaraapik":
            return "382";
        case "Lac Brochet":
            return "397";
        case "Lansdowne House":
            return "408";
        case "Little Grand Rapids":
            return "412";
        case "Lynn Lake":
            return "418";
        case "Moose Factory":
            return "444";
        case "Moose Lake":
            return "443";
        case "Moosonee":
            return "446";
        case "Nain":
            return "458";
        case "Nakina":
            return "460";
        case "Naujaat ":
            return "560";
        case "Norman Wells":
            return "468";
        case "Norway House":
            return "474";
        case "Oxford House":
            return "494";
        case "Pangnirtung":
            return "510";
        case "Patuanak":
            return "512";
        case "Pauingassi":
            return "515";
        case "Paulatuk":
            return "514";
        case "Peawanuck":
            return "517";
        case "Pelican Narrows":
            return "516";
        case "Pickle Lake":
            return "520";
        case "Pikangikum":
            return "522";
        case "Pinawa Solo":
            return "524";
        case "Pond Inlet":
            return "528";
        case "Poplar Hill":
            return "529";
        case "Poplar River":
            return "530";
        case "Portage La Loche ":
            return "532";
        case "Pukatawagan":
            return "536";
        case "Puvirnituq":
            return "534";
        case "Qikiqtarjuaq":
            return "140";
        case "Radisson":
            return "550";
        case "Rankin Inlet":
            return "552";
        case "Red Sucker Lake":
            return "558";
        case "Rigolet":
            return "564";
        case "Romaine":
            return "568";
        case "Rossville":
            return "570";
        case "Sachigo Lake":
            return "578";
        case "Salluit":
            return "584";
        case "Sandy Lake":
            return "588";
        case "Sanikiluaq":
            return "590";
        case "Sapotaweyak":
            return "591";
        case "Schefferville":
            return "592";
        case "Severn":
            return "596";
        case "Shamattawa":
            return "598";
        case "South Indian Lake":
            return "607";
        case "Southend":
            return "606";
        case "Split Lake":
            return "610";
        case "St. Theresa Point":
            return "582";
        case "Stanley Mission":
            return "612";
        case "Stony Rapids":
            return "614";
        case "Tadoule Lake":
            return "609";
        case "Taloyoak":
            return "608";
        case "Tsiigehtchic":
            return "241";
        case "Tuktoyaktuk":
            return "636";
        case "Tulita":
            return "256";
        case "Ulukhaktok":
            return "324";
        case "Umiujaq":
            return "645";
        case "Wabasca":
            return "653";
        case "Wasagamack":
            return "650";
        case "Waskaganish":
            return "654";
        case "Weagamow Lake":
            return "572";
        case "Webequie":
            return "658";
        case "Wunnumin Lake":
            return "668";
        case "York Landing":
            return "669";
        default: 
            return null;
    }
}

export default StoreNumber;