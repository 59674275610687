import { useEffect, useState } from "react";
import axios from "axios";
import subscribeBody from "./SubscribeRequest";
import triggerBody from "./TriggerRequest";
import LoadingScreen from "./LoadingScreen"
import StoreNumber from "./data/storeNumberData";
import storeData from "./data/storeData.json";



const SubscribeForm = () => {

    //http://connect-preference-api-dev.ds03.internal.tekside.io
    const lock = 0;
    const [validId, setValidId] = useState(false);
    const [newUser, setNewUser] = useState(false);
    const [enableForm, setEnableForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alreadySub, setAlreadySub] = useState(false);
    const [showSubscribeMessage, setshowSubscribeMessage] = useState(false);

    //User fields
    const [email, setEmail] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [province, setProvince] = useState("");
    const [store, setStore] = useState("");
    const [storeNumber, setStoreNumber] = useState("");

    const year = new Date().getFullYear();

    /******************** Permanent functions don't change between projects ********************/

    /**
     * On page load parses the id from the URL and verifies the token. 
     * If the token is valid the email is used to retrieve the contact information
     */
    useEffect(() => {
        const axiosConfig = { 
            headers : {
                appID: '$X5$W&&3hwZ$qQx+A3VT#dYWLV'
            }
        }
        // eslint-disable-next-line no-restricted-globals
        let params = new URLSearchParams(location.search);
        const tokenId = params.get("id");
        if(tokenId !== "") {
            let sendBody = triggerBody;
            sendBody.verify.id = tokenId;
            axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/trigger/verify`, sendBody.verify, axiosConfig).then(response => {
                if(response.data.success) {
                    setValidId(true);
                    setEnableForm(true);
                    getContact(response.data.email);
                } else {
                    setEnableForm(true);
                    console.log("Something went wrong");
                    console.log(response);
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lock])

    /**
     * Checks whether all the subscriptions in passed in list are of permission Unassigned
     * @param  {[Array]} subscriptionList An array containing the subscriptionPermissions for the contact
     * @return {[boolean]} Returns true if all are unassigned, otherwise false
     */ 
     const isNewUser = (subscriptionList) => {
        let isNew = true;
        for(let s of subscriptionList) {
            if(s.subscriptionPermission.permission !== "Unassigned") {
                isNew = false;
            }
        }
        setNewUser(isNew);
    }

    /**
     * Set's the contactData state with the information returned from doing a GET req with the users email, if they exist. If the user 
     * does not exist a message will be shown to the user informing them to fill out the fields and create their account.
     * @param  {[string]} email A string containing the users email.
     * @return {[void]} Returns nothing.
     */
     const getContact = (email) => {
        setEmail(email);
        const axiosConfig = { 
            headers : {
                appID: '$X5$W&&3hwZ$qQx+A3VT#dYWLV'
            }
        }
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/subscribe?email=${email}`, axiosConfig)
        .then(response => {
            if(response.status === 200) {
                setLoading(false);
                console.log(response.data.content);
                isNewUser(response.data.content[0].subscriptions);
                populateFields(response.data.content);
                
            } else {
                console.log(response.status)
                console.log(response)
                console.log("Something went wrong with getting the contact.");
            }
        });
    }

    /**
     * Used to check the validity of the email.
     * @param {String} email Passed in from the email input.
     * @returns True if the email is of vliad form, false otherwise.
     */
     function validateEmail(email){
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //return the opposite
        return !re.test(String(email).toLowerCase()); 
    }

    /**
     * Sends a post to the API with the information gathered from the fields the user has entered.
     * @param {event} event User presses subscribe button 
     */
    const handleSubscribeRequest = async (event) => {
        event.preventDefault();
        if (validateForm() && validId){
            setLoading(true);
            const sendBody = subscribeBody;
            const axiosConfig = {
                headers: {
                    appid: "$X5$W&&3hwZ$qQx+A3VT#dYWLV"
                },
            };
            updateSendBody(sendBody);
            sendBody.email = email;
            console.log(sendBody);

            try {
                axios.post(
                    `${process.env.REACT_APP_API_BASE_URL}/api/subscribe`,
                    sendBody,
                    axiosConfig
                ).then(response => {
                    //User was not subscribed to the service
                    console.log("User subscribed" + response.content);
                    setLoading(false);
                    setshowSubscribeMessage(true);
                }).catch((error) => {
                    //User is already subscribed to the service
                    console.log(error);
                    console.log(error.message);
                    console.log(error.response);
                    setLoading(false);
                    setAlreadySub(true);
                });
                
            } catch (error) { 
                console.error(error);
            }
        }
    };

    /********************************************************************************/

    /**
     * Set's all the hooks that contain field information with the data received from the GET req
     * @param  {[Array]} data A JSON object containing all the contact information recieved from the GET req
     */ 
    const populateFields = (data) => {
        var rawFields = JSON.parse(JSON.stringify(data[0].fields));
        console.log(rawFields);
        setFirstname(rawFields["First Name"]);
        setLastname(rawFields["Last Name"]);
        populateStoreSelect(rawFields.Province, rawFields["Store Name"]);
    }

    /**
     * Populates the select for stores based on province selection. Based on the store select 
     * hooks for the store and the store number are set.
     * @param {string} store the store that the user has selected.
     */
     const populateStoreSelect = (prov, stor) => {
        if(prov === "Newfoundland & Labrador") {
            prov = "NewfoundlandandLabrador";
        }

        const selectBox = document.querySelector('#store');
        let storeList = storeData[prov];

        if(prov !== province){
            removeAll(selectBox);
            setStore("");
            setStoreNumber("");
        }
        setProvince(prov);
        
        let newOption = new Option('Select your store', '');
        newOption.setAttribute('disabled', true);
        newOption.setAttribute('selected', true);

        selectBox.add(newOption, undefined); 
        
        for(let i = 0; i < storeList.length; i++) {
            let newOption = new Option(storeList[i], storeList[i]);
            selectBox.add(newOption, undefined);
        }

        if(stor) {
            document.querySelector("#store").value = stor;
            setStore(stor);
            setStoreNumber(StoreNumber(stor));
        }
    };

    /**
     * Removes all inner option elements from a select element.
     * @param {select} selectBox The select element.
     */
     function removeAll(selectBox) {
        while (selectBox.options.length > 0) {
            selectBox.remove(0);
        }
    }

    /**
     * Resets any error messages that were created from the user entering invalid input.
     */
    function resetErrors(){
        document.getElementById("emailInput").style.border = "1px #D0CCC7 solid";
        document.getElementById("firstNameInput").style.border = "1px #D0CCC7 solid";
        document.getElementById("lastNameInput").style.border = "1px #D0CCC7 solid";
        document.getElementById("province").style.border = "1px #D0CCC7 solid";
        document.getElementById("store").style.border = "1px #D0CCC7 solid";
        setAlreadySub(false);
    }

    /**
         * Checks whether the form the user has filled out is of valid form.
         * @returns true if the form is valid, false otherwise
         */
    function validateForm() {
        resetErrors();
        var success = true;
        if (email === ""){
            document.getElementById("emailInput").style.border = "1px red solid";
            success = false;
        } else if (validateEmail(email)){
            document.getElementById("emailInput").style.border = "1px red solid";
            success = false;
        } else if (firstname === ""){
            document.getElementById("firstNameInput").style.border = "1px red solid";
            success = false;
        } else if (lastname === ""){
            document.getElementById("lastNameInput").style.border = "1px red solid";
            success = false;
        } else if (province === ""){
            document.getElementById("province").style.border = "1px red solid";
            success = false;
        } else if (store === ""){
            document.getElementById("store").style.border = "1px red solid";
            success = false;
        }
        return success;
    }

    /**
     * Sets all the values in the body that gets sent to the POST req.
     * @param {Object} sendBody The JS object used in the POST req.
     */
    function updateSendBody(sendBody){
    
        sendBody.fields[0].value = email;
        sendBody.fields[1].value = firstname;
        sendBody.fields[2].value = lastname;
        if(province === "NewfoundlandandLabrador") {
            sendBody.fields[3].value = "Newfoundland & Labrador";
        } else {
            sendBody.fields[3].value = province;
        }
        sendBody.fields[4].value = store;
        sendBody.fields[5].value = storeNumber;
        
        
        sendBody.subscriptionPermissions[0].permission = "Express";
        sendBody.subscriptionPermissions[0].permissionCaptureDateTime = new Date().toISOString();
    }

    return ( 
        <div className="main-container">
            <a href="https://www.northmart.ca/" target="_blank" rel="noreferrer" className="header-container">
                <div className="header-body">
                    <img alt="Logo" src={require("./images/logo_northern.jpg")}/>
                </div>
            </a>
            {enableForm ?
                <>
                    {validId ?
                    <>
                    {!showSubscribeMessage ?
                        <div className="body-container">
                            <div className="body-header">
                                <h1>Subscribe</h1>
                                <p>Subscribe to receive information about great deals and special promotions from your local Northern/North Mart store.</p>
                            </div>
                            <form id="contact" name="contact" onSubmit={handleSubscribeRequest} className="form-section">
                                <div className="form-field" id="email">
                                    <input type="text" value={ email } placeholder="Email" name="email" id="emailInput" onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className="form-field" id="firstName">
                                    <input type="text" value={ firstname } placeholder="First Name" name="firstNameInput" id="firstNameInput" onChange={(e) => setFirstname(e.target.value)} />
                                </div>
                                <div className="form-field" id="lastName">
                                    <input type="text" value={ lastname } placeholder="Last Name" name="lastNameInput" id="lastNameInput" onChange={(e) => setLastname(e.target.value)} />
                                </div>    
                                <div className="form-field">
                                    <select name="province" id="province" value={province} className="form-select-field" onChange={(e) => populateStoreSelect(e.target.value, null)}>
                                        <option value="default" disabled selected>Province/Territory</option>
                                        <option value="Alberta">Alberta</option>
                                        <option value="Manitoba">Manitoba</option>
                                        <option value="NewfoundlandandLabrador">Newfoundland and Labrador</option>
                                        <option value="Northwest Territories">Northwest Territories</option>
                                        <option value="Nunavut">Nunavut</option>
                                        <option value="Ontario">Ontario</option>
                                        <option value="Quebec">Quebec</option>
                                        <option value="Saskatchewan">Saskatchewan</option>
                                    </select>
                                </div>
                                <div className="form-field">
                                    <select name="store" id="store" className="form-select-field" onChange={(e) => {
                                        setStore(e.target.value);
                                        setStoreNumber(StoreNumber(e.target.value));
                                        console.log(province)
                                        console.log(store)
                                        console.log(storeNumber)
                                    }}>
                                        <option value="default" disabled selected>Select your store</option>
                                    </select>
                                </div>
                                <div className="button-field">
                                    <label id="button-label" htmlFor="submit" className="form-field-label"></label>
                                    <button className="button-submit">SUBMIT</button>
                                    {loading && <LoadingScreen/>}
                                    {alreadySub && <div className="text-header-large">No changes we're made to your information.</div>}
                                </div>
                            </form>
                            <br />
                            
                        </div>
                    :
                        <div className="body-container">
                            <div className="text-header-large">{newUser ?  "Thank you for subscribing." : "Your contact information has been updated."}</div>
                        </div>
                    }
                    </>
                :
                    <div className="rounded-section-centered">
                        <p>Your secure link seems to have a bad ID. This can occur if the ID is expired or if it was edited. To resolve this issue please click here to send another secure link to the email address you'd like to subscribe with.</p>
                        <button><a href="/">Re-Send</a></button>
                    </div>
                }
                </>
            :
                <LoadingScreen/>
            }
            <div className="footer-container">
                <div className="footer-body">
                    <p>You may withdraw your consent at any time. Please refer to our <a className="footer-legal-links" href="https://www.northmart.ca/legal-privacy">Privacy Policy</a> or <a className="footer-legal-links" href="https://www.northmart.ca/contact-us">Contact Us</a> for more details.</p>
                    <img className="footer-logo" alt="North West Logo" src={require("./images/logoBottom.jpg")}/>
                </div>
                <div className="footer-legal">
                    The North West Company 77 Main Street Winnipeg, MB Canada R3C 1A3 (204) 943-0881
                    <br></br>
                    <br></br>
                    © {year} The North West Company
                </div>
            </div>
        </div>
     );
}
 
export default SubscribeForm; 