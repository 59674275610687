import "./styles/App.css";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import SendTriggerForm from "./SendTriggerForm";
import SubscribeForm from "./SubscribeForm";
import UnsubscribeForm from "./UnsubscribeForm";


function App() {
  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <div className="App">
          <Route exact path="/">
            <Redirect to="/subscribe" />
          </Route>
          <Route path="/subscribe" exact component={() => <SendTriggerForm />} />
          <Route path="/editPreferences" exact component={() => <SubscribeForm />} />
          <Route path="/unsubscribe" exact component={() => <UnsubscribeForm/>} />
        </div>
      </QueryParamProvider>
    </Router>
  );
}

export default App;
